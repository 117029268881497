import {ScrollProvider} from "./components/Scroll/Scroll";
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import './lang';
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {getUserByToken} from "./store/slices/userSlice";
import FullLoading from "./components/LoadingComponents/FullLoading";
import {REACT_APP_NODE_ENV} from "./constants/env";
import {disableReactDevTools} from "@fvilers/disable-react-devtools";
import BackTop from "./pages/components/BackTop/BackTop";
import LanguageButton from "./pages/components/Lan/LanguageButton";
import HomePage from "./pages/Home/HomePage";
import ProductPage from "./pages/Product/ProductPage";
import StatsPage from "./pages/Stats/StatsPage";
import LoginPage from "./pages/Login/LoginPage";
import KaspiComplianceCategoryPage from "./pages/KaspiCompliance/KaspiComplianceCategoryPage";
import Logout from "./pages/Logout/Logout";
import KaspiComplianceAttributePage from "./pages/KaspiCompliance/KaspiComplianceAttributePage";
import KaspiComplianceValuePage from "./pages/KaspiCompliance/KaspiComplianceValuePage";

if (REACT_APP_NODE_ENV === 'production') {
    disableReactDevTools();
}
function App() {

    return (
        <ScrollProvider>
            <BackTop/>
            {/*<LanguageButton/>*/}
            <Router>
                <Routes>
                    <Route path='/login' element={<LoginPage/>}/>
                    <Route path='/logout' element={<Logout/>}/>
                    <Route path='/' element={<KaspiComplianceCategoryPage/>}/>
                    <Route path='/kaspi-compliance/:kaspiComplianceCategoryId/:kaspiCategoryId' element={<KaspiComplianceAttributePage/>}/>
                    <Route path='/kaspi-compliance/:kaspiComplianceCategoryId/:kaspiCategoryId/:kaspiComplianceAttributeId' element={<KaspiComplianceValuePage/>}/>
                    {/*<Route path='/trendyol/' element={<HomePage/>}/>*/}
                    {/*<Route path='/trendyol/product/:id' element={<ProductPage/>}/>*/}
                    {/*<Route path='/trendyol/stats' element={<StatsPage/>}/>*/}
                    {/*<Route path='*' element={<Navigate to={'/login/'} replace/>}/>*/}
                </Routes>
            </Router>
        </ScrollProvider>
    );
}

export default App;
