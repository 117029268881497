import axios from "axios";
import {API_BASE_URL} from "../constants/api";
import {ACCESS_TOKEN} from "../constants/auth";

const KaspiService = {};
KaspiService.getKaspiCategories = async () => {
    return axios({
        method: "get",
        url: API_BASE_URL + "/api/admin/kaspi/category/get",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}
KaspiService.getKaspiCategoryAttributes = async () => {
    return axios({
        method: "get",
        url: API_BASE_URL + "/api/admin/kaspi/category/attiribute/get",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}
KaspiService.getKaspiCategoryAttributeValues = async () => {
    return axios({
        method: "get",
        url: API_BASE_URL + "/api/admin/kaspi/category/attiribute/value/get",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}
export default KaspiService;