import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {clearUser} from "../../store/slices/userSlice";
import FullLoading from "../../components/LoadingComponents/FullLoading";

const Logout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    useEffect(() => {
        localStorage.removeItem('Authorization');
        localStorage.removeItem('ExpirationDate');
        navigate('/login')
    }, [navigate])

    return (
        <FullLoading/>
    );
};

export default Logout;