export const clrs = {
    white: "#ffffff",
    whiter: "#F5F5F5",
    black: "#000000",
    grey: "#6F8695",
    blackerBlue: "#163269",
    // whiterGreen: "#60AB9A",
    blacker: "#292929",
    whiterBlacker: "#8c8b8b",
    blue2: "#4c84ff",
    darkerBlue :"#004E64",
    blueForIcon : "#1677ff",

    yellow: "#FEB50C",
    red: "#FE4646",
    blue: "#005CF9",
    green: "#49AB6C",
    blacker2: "#373737",

    whiterBlue: "#76a3fd",
    whiterRed: "#fc8888",
    whiterGreen: "#8cb499",

    blackerBlue2: "#01003F",
    purple: "#510AC9",
    whiterBlue2: "#00FFEB",
    blood: "#DB0613",
}