import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Button, Flex, Form, message, Select, Table} from "antd";
import {FullscreenExitOutlined} from "@ant-design/icons";
import IntlMessage from "../../components/IntlMessage/IntlMessage";
import KaspiComplianceService from "../../services/KaspiComplianceService";
import ClassificationService from "../../services/ClassificationService";

const KaspiComplianceAttributePage = () => {
    const {kaspiCategoryId, kaspiComplianceCategoryId, kaspiComplianceAttributeId} = useParams();
    const navigate = useNavigate();

    const [kaspiComplianceValues, setKaspiComplianceValues] = useState([]);
    const [trendyolValues, setTrendyolValues] = useState([]);
    const [trendyolCategory, setTrendyolCategory] = useState(null);
    const [trendyolAttribute, setTrendyolAttribute] = useState(null);
    const [kaspiComplianceAttribute, setKaspiComplianceAttribute] = useState(null);
    const [kaspiAttribute, setKaspiAttribute] = useState(null);

    const [loading, setLoading] = useState(true);

    const [realTrendyolValueList, setRealTrendyolValueList] = useState([]);

    const [form] = Form.useForm();

    useEffect(() => {
        message.loading(<IntlMessage id={'loading'}/>, 0);
        KaspiComplianceService.getAllKaspiComplianceCategoryAttributeValues(kaspiComplianceAttributeId)
            .then((r) => {
                message.destroy();
                message.success(<IntlMessage id={'success'}/>, 5);
                setKaspiComplianceValues(r.data);
                setKaspiComplianceAttribute(r.data?.at(0)?.kaspiComplianceAttribute);
                setTrendyolCategory(r.data?.at(0)?.kaspiComplianceAttribute?.kaspiComplianceCategory?.category);
                setTrendyolAttribute(r.data?.at(0)?.kaspiComplianceAttribute?.attribute);
                setKaspiAttribute(r.data?.at(0)?.kaspiComplianceAttribute?.kaspiAttribute);
            })
            .catch(() => {
                message.destroy();
                message.error(<IntlMessage id={'error'}/>, 5);
            })


    }, []);

    useEffect(() => {
        if (trendyolCategory) {
            ClassificationService.getCategoryAttributeValues(trendyolAttribute.id)
                .then((r) => {
                    message.destroy();
                    message.success(<IntlMessage id={'success'}/>, 5);
                    setTrendyolValues(r.data);
                    setRealTrendyolValueList(r.data.map((trendyolValue) => {
                        return  {
                            label: `${trendyolValue?.names[0]?.text} | ${trendyolValue?.trendyolName}`,
                            value: trendyolValue.id
                        }
                    }));
                    setLoading(false);
                })
                .catch(() => {
                    message.destroy();
                    message.error(<IntlMessage id={'error'}/>, 5);
                })
        }
    }, [trendyolAttribute]);

    useEffect(() => {
        if (trendyolValues.length > 0) {
            let removeRealValues = [];
            for (let kaspiComplianceValue of kaspiComplianceValues) {
                const mappedValues = kaspiComplianceValue.values.map((value) => value.id);

                form.setFieldValue(kaspiComplianceValue.id + "-trendyolValues", mappedValues);
                removeRealValues = [...removeRealValues, ...mappedValues];
            }
            let resultList = [];
            for (let trendyolValue of trendyolValues) {
                resultList.push({
                    value: trendyolValue.id,
                    label: trendyolValue?.names[0]?.text + " | " + trendyolValue?.trendyolName,
                    disabled: removeRealValues.some((removeRealValue) => removeRealValue === trendyolValue.id)
                })
            }
            setRealTrendyolValueList(resultList)
        }
    }, [trendyolValues]);
    function adjustRealTrendyolValueList() {
        let removeRealValues = [];
        for (let kaspiComplianceValue of kaspiComplianceValues) {
            const mappedValues = form.getFieldValue(kaspiComplianceValue.id + "-trendyolValues");

            removeRealValues = [...removeRealValues, ...mappedValues];
        }
        let resultList = [];
        for (let trendyolValue of trendyolValues) {
            resultList.push({
                value: trendyolValue.id,
                label: trendyolValue?.names[0]?.text + " | " + trendyolValue?.trendyolName,
                disabled: removeRealValues.some((removeRealValue) => removeRealValue === trendyolValue.id)
            })
        }
        setRealTrendyolValueList(resultList)
    }

    async function handleOnSaveValues() {
        message.loading(<IntlMessage id={'loading'}/>, 0);
        for (let kaspiComplianceValue of kaspiComplianceValues) {
            const valueIds = form.getFieldValue(kaspiComplianceValue.id + "-trendyolValues");
            const kaspiComplianceValueId = kaspiComplianceValue.id;
            if (valueIds.length === 0) {
                continue;
            }
            await KaspiComplianceService.setCategoryAttributeValues(valueIds, kaspiComplianceValueId)
                .then(() => {
                    message.success(<IntlMessage id={'success'}/>, 5);
                })
                .catch(() => {
                    message.error(<IntlMessage id={'error'}/>, 5);
                })
        }
        message.destroy();
        message.success(<IntlMessage id={'success'}/>, 5);
    }

    const columns = [
        {
            title: 'Название значения Kaspi',
            render: (_, record) => (
                <p>
                    {record?.kaspiValue?.code} | {record?.kaspiValue?.name}
                </p>
            ),
        },
        {
            title: 'Значения Trendyol',
            render: (_, record) => (
                <Flex style={{width: "100%"}} gap={10}>
                    <Form form={form} style={{width: "100%"}}>
                        <Form.Item style={{margin: 0, width: "100%"}} name={record.id + "-trendyolValues"}>
                            <Select
                                style={{width: "100%"}}
                                showSearch
                                optionFilterProp="label"
                                mode={"multiple"}
                                allowClear
                                onChange={() => {adjustRealTrendyolValueList()}}
                                options={realTrendyolValueList}
                                // options={trendyolValues.map((trendyolValue) => {
                                //     return {
                                //         label: `${trendyolValue?.names[0]?.text} | ${trendyolValue?.trendyolName}`,
                                //         value: trendyolValue.id
                                //     }
                                // })}
                            />
                        </Form.Item>
                    </Form>
                </Flex>
            ),
            width: "70%"
        },
    ];

    return (
        <Flex style={{width: "100%", padding: 50}} align={"center"} justify={"center"}>
            <Flex style={{width: "100%", position: "relative", maxWidth: 1440}} gap={35} vertical>
                <Flex gap={20} >
                    <Button type={"primary"} onClick={() => {
                        navigate('/login')
                    }}>
                        Войти
                    </Button>
                    <Button onClick={() => {
                        navigate('/logout')
                    }}>
                        Выйти
                    </Button>
                </Flex>
                <Flex>
                    <Button type={"primary"} onClick={() => {
                        navigate(`/kaspi-compliance/${kaspiComplianceCategoryId}/${kaspiCategoryId}`)
                    }}>
                        Назад к атрибутам
                    </Button>
                </Flex>
                <Flex vertical gap={10}>
                    <p><strong>Каспи категория: </strong>{kaspiComplianceAttribute?.kaspiCategory.code} | {kaspiComplianceAttribute?.kaspiCategory.title}</p>
                    <p><strong>Trendyol категория: </strong>{trendyolCategory?.names[0]?.text}</p>
                    <p><strong>Kaspi атрибут: </strong>{kaspiAttribute?.code}</p>
                    <p><strong>Trendyol атрибут: </strong>{trendyolAttribute?.names[0]?.text} | {trendyolAttribute?.trendyolName}</p>
                </Flex>
                <Flex>
                    <Button type={"primary"} onClick={handleOnSaveValues}>
                        Сохранить
                    </Button>
                </Flex>
                <Table
                    loading={loading}
                    bordered
                    columns={columns}
                    pagination={false}
                    dataSource={kaspiComplianceValues}
                    rowKey={record => record.id}
                    style={{
                        width: "100%",
                    }}
                />
            </Flex>
        </Flex>
    );
};

export default KaspiComplianceAttributePage;