import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import {Button, Checkbox, Flex, Form, Input, message, Select, Table} from "antd";
import {FullscreenExitOutlined} from "@ant-design/icons";
import IntlMessage from "../../components/IntlMessage/IntlMessage";
import KaspiComplianceService from "../../services/KaspiComplianceService";
import ClassificationService from "../../services/ClassificationService";

const KaspiComplianceAttributePage = () => {
    const {kaspiCategoryId, kaspiComplianceCategoryId} = useParams();
    const navigate = useNavigate();

    const [kaspiComplianceAttributes, setKaspiComplianceAttributes] = useState([]);
    const [trendyolAttributes, setTrendyolAttributes] = useState([]);
    const [trendyolCategory, setTrendyolCategory] = useState(null);

    const [form] = Form.useForm();

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        message.loading(<IntlMessage id={'loading'}/>, 0);
        KaspiComplianceService.getAllKaspiComplianceCategoryAttributes(kaspiCategoryId, kaspiComplianceCategoryId)
            .then((r) => {
                setKaspiComplianceAttributes(r.data);
                setTrendyolCategory(r.data?.at(0)?.kaspiComplianceCategory.category);
            })
            .catch(() => {
                message.error(<IntlMessage id={'error'}/>, 5);
            })
    }, []);

    useEffect(() => {
        if (trendyolCategory) {
            ClassificationService.getCategoryAttributes(trendyolCategory.id)
                .then((r) => {
                    message.destroy();
                    message.success(<IntlMessage id={'success'}/>, 5);
                    setTrendyolAttributes(r.data);
                    setLoading(false);
                })
                .catch(() => {
                    message.destroy();
                    message.error(<IntlMessage id={'error'}/>, 5);
                })
        }
    }, [trendyolCategory]);

    function handleOnChooseAttribute(attributeId, kaspiComplianceAttributeId) {
        message.loading(<IntlMessage id={'loading'}/>, 0);
        KaspiComplianceService.setCategoryAttribute(attributeId, kaspiComplianceAttributeId)
            .then((r) => {
                message.destroy();
                message.success(<IntlMessage id={'success'}/>, 5);
                setKaspiComplianceAttributes(
                    kaspiComplianceAttributes.map((kaspiComplianceAttribute) =>
                        kaspiComplianceAttribute.id === r.data.id ? r.data : kaspiComplianceAttribute
                    )
                );
            })
            .catch(() => {
                message.destroy();
                message.error(<IntlMessage id={'error'}/>, 5);
            })
    }

    function handleOnSaveAttributeDefaultValue(kaspiComplianceAttributeId) {
        message.loading(<IntlMessage id={'loading'}/>, 0);
        const defaultValue = form.getFieldValue(kaspiComplianceAttributeId + "-defaultValue");
        KaspiComplianceService.setCategoryAttributeDefaultValue(kaspiComplianceAttributeId, defaultValue)
            .then((r) => {
                message.destroy();
                message.success(<IntlMessage id={'success'}/>, 5);
                setKaspiComplianceAttributes(
                    kaspiComplianceAttributes.map((kaspiComplianceAttribute) =>
                        kaspiComplianceAttribute.id === r.data.id ? r.data : kaspiComplianceAttribute
                    )
                );
            })
            .catch(() => {
                message.destroy();
                message.error(<IntlMessage id={'error'}/>, 5);
            })
    }

    function handleOnChooseAttributeDefaultBoolValue(kaspiComplianceAttributeId, defaultBoolValue) {
        message.loading(<IntlMessage id={'loading'}/>, 0);
        KaspiComplianceService.setCategoryAttributeDefaultBoolValue(kaspiComplianceAttributeId, defaultBoolValue)
            .then((r) => {
                message.destroy();
                message.success(<IntlMessage id={'success'}/>, 5);
                setKaspiComplianceAttributes(
                    kaspiComplianceAttributes.map((kaspiComplianceAttribute) =>
                        kaspiComplianceAttribute.id === r.data.id ? r.data : kaspiComplianceAttribute
                    )
                );
            })
            .catch(() => {
                message.destroy();
                message.error(<IntlMessage id={'error'}/>, 5);
            })
    }

    const columns = [
        {
            title: 'Название атрибута Kaspi',
            render: (_, record) => (
                <>
                    {record?.kaspiAttribute?.code}
                </>
            ),
        },
        {
            title: 'Атрибут Trendyol',
            render: (_, record) => (
                <Flex style={{width: "100%"}} gap={10}>
                    <Select
                        style={{width: "100%"}}
                        showSearch
                        optionFilterProp="label"
                        defaultValue={record.attribute?.id}
                        onChange={(value) => {handleOnChooseAttribute(value, record.id)}}
                        options={trendyolAttributes.map((trendyolAttribute) => {
                            return {
                                label: `${trendyolAttribute.names[0].text} | ${trendyolAttribute.trendyolName}`,
                                value: trendyolAttribute.id
                            }
                        })}
                    />
                </Flex>
            ),
            width: "25%"
        },
        {
            title: 'Дефолтное значение',
            render: (_, record) => {
                if (record?.kaspiAttribute?.type === 'string' || record?.kaspiAttribute?.type === 'enum' || record?.kaspiAttribute?.type === 'number') {
                    return (
                        <Flex style={{width: "100%"}} gap={10}>
                            <Form form={form} initialValues={{
                                [`${record.id}-defaultValue`]: record.defaultValue
                            }}>
                                <Form.Item style={{margin: 0}} name={record.id + "-defaultValue"}>
                                    <Input />
                                </Form.Item>
                            </Form>

                            <Button onClick={() => {handleOnSaveAttributeDefaultValue(record.id)}}>
                                Сохр.
                            </Button>
                        </Flex>
                    );
                } else if (record?.kaspiAttribute?.type === 'boolean') {
                    return (
                        <Flex style={{width: "100%"}} gap={10}>
                            <Select
                                style={{width: "100%"}}
                                defaultValue={record?.defaultBoolValue}
                                onChange={(value) => {handleOnChooseAttributeDefaultBoolValue(record.id, value)}}
                                options={[
                                    {
                                        value: true,
                                        label: "true"
                                    },
                                    {
                                        value: false,
                                        label: "false"
                                    },
                                ]}
                            />
                        </Flex>
                    );
                }
                return null;
            },
            width: "20%"
        },
        {
            title: 'Тип атрибута Kaspi ',
            render: (_, record) => (
                <>
                    {record?.kaspiAttribute?.type}
                </>
            ),
            width: "5%"
        },
        {
            title: 'Открыть значения ',
            render: (_, record) => (
                <>
                    {record?.kaspiAttribute?.type === 'enum' &&
                        <Link to={`/kaspi-compliance/${kaspiComplianceCategoryId}/${kaspiCategoryId}/${record.id}`}>
                            <Button style={{fontSize: "1.2rem"}}>
                                <FullscreenExitOutlined />
                            </Button>
                        </Link>
                    }
                </>
            ),
            width: "5%"
        },
    ];

    return (
        <Flex style={{width: "100%", padding: 50}} align={"center"} justify={"center"}>
            <Flex style={{width: "100%", position: "relative", maxWidth: 1440}} gap={35} vertical>
                <Flex gap={20} >
                    <Button type={"primary"} onClick={() => {
                        navigate('/login')
                    }}>
                        Войти
                    </Button>
                    <Button onClick={() => {
                        navigate('/logout')
                    }}>
                        Выйти
                    </Button>
                </Flex>
                <Flex>
                    <Button type={"primary"} onClick={() => {
                        navigate('/')
                    }}>
                        Назад к категориям
                    </Button>
                </Flex>
                <Flex vertical gap={10}>
                    <p><strong>Каспи категория: </strong>{kaspiComplianceAttributes?.at(0)?.kaspiCategory.code} | {kaspiComplianceAttributes?.at(0)?.kaspiCategory.title}</p>
                    <p><strong>Trendyol категория: </strong>{trendyolCategory?.names[0].text}</p>
                </Flex>
                <Table
                    bordered
                    columns={columns}
                    pagination={false}
                    dataSource={kaspiComplianceAttributes}
                    rowKey={record => record.id}
                    style={{
                        width: "100%",
                    }}
                    loading={loading}
                />
                <Flex vertical gap={10}>
                    <p><strong>Дефолтное значения:</strong></p>
                    <p>barcode(Например: 8683047178400)</p>
                    <p>productId(Например: 217582020)</p>
                    <p>modelNo(Например: TWOSS23BZ00152)</p>
                    <p>brand(Например: Trendyol Curve)</p>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default KaspiComplianceAttributePage;