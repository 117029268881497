import axios from "axios";
import {API_BASE_URL} from "../constants/api";
import {ACCESS_TOKEN} from "../constants/auth";

const KaspiComplianceService = {};
KaspiComplianceService.getKaspiComplianceCategories = async () => {

    return axios({
        method: "get",
        url: API_BASE_URL + "/api/admin/kaspi-compliance/category/get",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}
KaspiComplianceService.getAllKaspiComplianceCategoryAttributes = async (kaspiCategoryId, kaspiComplianceCategoryId) => {

    return axios({
        method: "get",
        url: API_BASE_URL + "/api/admin/kaspi-compliance/category/attribute/get",
        params: {
            kaspiCategoryId,
            kaspiComplianceCategoryId
        },
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}
KaspiComplianceService.getAllKaspiComplianceCategoryAttributeValues = async (kaspiComplianceAttributeId) => {

    return axios({
        method: "get",
        url: API_BASE_URL + "/api/admin/kaspi-compliance/category/attribute/value/get",
        params: {
            kaspiComplianceAttributeId,
        },
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

KaspiComplianceService.setKaspiCategory = async (kaspiCategoryId, kaspiComplianceCategoryId, gender) => {
    let data = new FormData();
    data.append("kaspiCategoryId", kaspiCategoryId);
    data.append("kaspiComplianceCategoryId", kaspiComplianceCategoryId);
    data.append("gender", gender);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/admin/kaspi-compliance/set/kaspi/category",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}
KaspiComplianceService.setCategoryAttribute = async (attributeId, kaspiComplianceAttributeId) => {
    let data = new FormData();
    data.append("attributeId", attributeId);
    data.append("kaspiComplianceAttributeId", kaspiComplianceAttributeId);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/admin/kaspi-compliance/set/attribute",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}
KaspiComplianceService.setCategoryAttributeDefaultValue = async (kaspiComplianceAttributeId, defaultValue) => {
    let data = new FormData();
    data.append("defaultValue", defaultValue);
    data.append("kaspiComplianceAttributeId", kaspiComplianceAttributeId);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/admin/kaspi-compliance/set/attribute/default/value",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}
KaspiComplianceService.setCategoryAttributeDefaultBoolValue = async (kaspiComplianceAttributeId, defaultBoolValue) => {
    let data = new FormData();
    data.append("defaultBoolValue", defaultBoolValue);
    data.append("kaspiComplianceAttributeId", kaspiComplianceAttributeId);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/admin/kaspi-compliance/set/attribute/default/value",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}
KaspiComplianceService.setCategoryAttributeValues = async (valueIds, kaspiComplianceValueId) => {
    let data = new FormData();
    data.append("valueIds", valueIds);
    data.append("kaspiComplianceValueId", kaspiComplianceValueId);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/admin/kaspi-compliance/set/values",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

KaspiComplianceService.createKaspiComplianceAttributes = async (kaspiCategoryId, kaspiComplianceCategoryId) => {
    let data = new FormData();
    data.append("kaspiCategoryId", kaspiCategoryId);
    data.append("kaspiComplianceCategoryId", kaspiComplianceCategoryId);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/admin/kaspi-compliance/create/kaspi/compliance/attributes",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}
export default KaspiComplianceService;