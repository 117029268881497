import axios from "axios";
import {API_BASE_URL} from "../constants/api";
import {ACCESS_TOKEN} from "../constants/auth";

const ClassificationService = {};
ClassificationService.getCategoryAttributes = async (categoryId) => {
    return axios({
        method: "get",
        url: API_BASE_URL + "/api/admin/classification/category/attiribute/get",
        params: {
            categoryId
        },
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}
ClassificationService.getCategoryAttributeValues = async (attributeId) => {
    return axios({
        method: "get",
        url: API_BASE_URL + "/api/admin/classification/category/attiribute/value/get",
        params: {
            attributeId
        },
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}
export default ClassificationService;