import React, {useState} from 'react';
import {Button, Flex, Form, Input, message} from "antd";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import AuthService from "../../services/AuthService";
import IntlMessage, {IntlMessageText} from "../../components/IntlMessage/IntlMessage";
import {getUserByToken} from "../../store/slices/userSlice";
import FormItem from "antd/es/form/FormItem";
import {KeyOutlined, UserOutlined} from "@ant-design/icons";
import classes from '../global_styles.module.css';
import {ACCESS_TOKEN} from "../../constants/auth";

const LoginPage = () => {
    const [form] = Form.useForm();

    const username = Form.useWatch('username', form);
    const password = Form.useWatch('password', form);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);



    function handleOnSubmit() {
        form.validateFields()
            .then(() => {
                message.loading(<IntlMessage id={'loading'}/>, 0);
                AuthService.login(username, password)
                    .then((result) => {
                        // console.log(result.data);
                        message.destroy();
                        message.success(<IntlMessage id={'success'}/>, 5);
                        localStorage.setItem('Authorization', result?.data.access_token);
                        localStorage.setItem('ExpirationDate', result?.data.expiration_date);
                        localStorage.setItem('Refresh', result?.data.refresh_token);
                        navigate('/');
                    })
                    .catch((result) => {
                        message.destroy();
                        if (result?.response.data === "user not found" || result?.response.data === "password is incorrect") {
                            message.error(<IntlMessage id={'incorrectPasswordOrUsername'}/>, 5);
                        } else {
                            message.error(<IntlMessage id={'error'}/>, 5);
                        }
                    })
            })
            .catch(() => {

            })
    }

    const token = localStorage.getItem(ACCESS_TOKEN);

    if (token) {
        navigate('/')
    }

    return (
        <Flex style={{width: "100%", padding: 50}} align={"center"} justify={"center"}>
            <Flex style={{width: "100%", position: "relative", maxWidth: 500}}>
                <Form
                    onKeyUp={(e) => {if (e.key === "Enter") {handleOnSubmit()}}}
                    form={form}
                    className={classes.form}
                    style={{justifyContent: "center"}}>
                    <FormItem rules={[{
                        required: true,
                        message: <IntlMessage id={'requiredField'}/>,
                    },
                    ]} className={classes.formInputBlock} name="username">
                        <Input rootClassName={classes.formInput}
                               placeholder={IntlMessageText.getText({id: "username"})}
                               prefix={<UserOutlined style={{marginRight: 5}}/>}
                        />
                    </FormItem>
                    <FormItem rules={[{
                        required: true,
                        message: <IntlMessage id={'requiredField'}/>,
                    },
                    ]} className={classes.formInputBlock} name="password">
                        <Input.Password rootClassName={classes.formInput}
                                        placeholder={IntlMessageText.getText({id: "password"})}
                                        prefix={<KeyOutlined style={{marginRight: 5}}/>}
                        />
                    </FormItem>
                    <FormItem className={classes.formInputBlock}>
                        <Flex style={{width: "100%"}} align={"center"} justify={"center"}>
                            <Button
                                type={"primary"}
                                onClick={handleOnSubmit}>
                                <IntlMessage id={'login'}/>
                            </Button>
                        </Flex>
                    </FormItem>
                </Form>
            </Flex>
        </Flex>

    );
};

export default LoginPage;