import React, {useEffect, useState} from 'react';
import {Button, Flex, message, Popconfirm, Select, Table} from "antd";
import KaspiComplianceService from "../../services/KaspiComplianceService";
import IntlMessage from "../../components/IntlMessage/IntlMessage";
import {Link, useNavigate} from "react-router-dom";
import KaspiService from "../../services/KaspiService";
import {FullscreenExitOutlined, PlusCircleOutlined} from "@ant-design/icons";

const KaspiComplianceCategoryPage = () => {

    const navigate = useNavigate();

    const [kaspiComplianceCategories, setKaspiComplianceCategories] = useState([]);
    const [kaspiCategories, setKaspiCategories] = useState([]);

    useEffect(() => {
        message.loading(<IntlMessage id={'loading'}/>, 0);
        KaspiComplianceService.getKaspiComplianceCategories()
            .then((r) => {
                setKaspiComplianceCategories(r.data);
            })
            .catch(() => {
                message.error(<IntlMessage id={'error'}/>, 5);
            })

        KaspiService.getKaspiCategories()
            .then((r) => {
                message.destroy();
                message.success(<IntlMessage id={'success'}/>, 5);
                setKaspiCategories(r.data);
            })
            .catch(() => {
                message.destroy();
                message.error(<IntlMessage id={'error'}/>, 5);
            })
    }, []);

    function handleOnChooseKaspiCategory(kaspiCategoryId, kaspiComplianceCategoryId, gender) {
        message.loading(<IntlMessage id={'loading'}/>, 0);
        KaspiComplianceService.setKaspiCategory(kaspiCategoryId, kaspiComplianceCategoryId, gender)
            .then((r) => {
                message.destroy();
                message.success(<IntlMessage id={'success'}/>, 5);
                setKaspiComplianceCategories(
                    kaspiComplianceCategories.map((kaspiComplianceCategory) =>
                        kaspiComplianceCategory.id === r.data.id ? r.data : kaspiComplianceCategory
                    )
                );
            })
            .catch(() => {
                message.destroy();
                message.error(<IntlMessage id={'error'}/>, 5);
            })
    }

    const columns = [
        {
            title: 'Название категории Trendyol',
            render: (_, record) => (
                <>
                    {record.category.names[0].text}
                </>
            ),
        },
        {
            title: 'Женский (Kaspi категория)',
            render: (_, record) => (
                <Flex style={{width: "100%"}} gap={10}>
                    <Select
                        style={{width: "100%"}}
                        showSearch
                        optionFilterProp="label"
                        defaultValue={record.kaspiCategory_female?.id}
                        onChange={(value) => {handleOnChooseKaspiCategory(value, record.id, 1)}}
                        options={kaspiCategories.map((kaspiCategory) => {
                            return {
                                label: kaspiCategory.code,
                                value: kaspiCategory.id
                            }
                        })}
                    />
                    <Link to={`/kaspi-compliance/${record.id}/${record?.kaspiCategory_female?.id}`}>
                        <Button style={{fontSize: "1.2rem"}}>
                            <FullscreenExitOutlined />
                        </Button>
                    </Link>

                </Flex>
            ),
            width: "25%"
        },
        {
            title: 'Мужской (Kaspi категория)',
            render: (_, record) => (
                <Flex style={{width: "100%"}} gap={10}>
                    <Select
                        style={{width: "100%"}}
                        showSearch
                        optionFilterProp="label"
                        defaultValue={record.kaspiCategory_male?.id}
                        onChange={(value) => {handleOnChooseKaspiCategory(value, record.id, 2)}}
                        options={kaspiCategories.map((kaspiCategory) => {
                            return {
                                label: kaspiCategory.code,
                                value: kaspiCategory.id
                            }
                        })}
                    />
                    <Link to={`/kaspi-compliance/${record.id}/${record?.kaspiCategory_male?.id}`}>
                        <Button style={{fontSize: "1.2rem"}}>
                            <FullscreenExitOutlined />
                        </Button>
                    </Link>
                </Flex>
            ),
            width: "25%"
        },
        {
            title: 'Унисекс (Kaspi категория)',
            render: (_, record) => (
                <Flex style={{width: "100%"}} gap={10}>
                    <Select
                        style={{width: "100%"}}
                        showSearch
                        optionFilterProp="label"
                        defaultValue={record.kaspiCategory_unisex?.id}
                        onChange={(value) => {handleOnChooseKaspiCategory(value, record.id, 3)}}
                        options={kaspiCategories.map((kaspiCategory) => {
                            return {
                                label: kaspiCategory.code,
                                value: kaspiCategory.id
                            }
                        })}
                    />
                    <Link to={`/kaspi-compliance/${record.id}/${record?.kaspiCategory_unisex?.id}`}>
                        <Button style={{fontSize: "1.2rem"}}>
                            <FullscreenExitOutlined />
                        </Button>
                    </Link>

                </Flex>
            ),
            width: "25%"
        },
    ];

    return (
        <Flex style={{width: "100%", padding: 50}} align={"center"} justify={"center"}>
            <Flex style={{width: "100%", position: "relative", maxWidth: 1440}} gap={35} vertical>
                <Flex gap={20} >
                    <Button type={"primary"} onClick={() => {
                        navigate('/login')
                    }}>
                        Войти
                    </Button>
                    <Button onClick={() => {
                        navigate('/logout')
                    }}>
                        Выйти
                    </Button>
                </Flex>
                <Table
                    bordered
                    columns={columns}
                    pagination={false}
                    dataSource={kaspiComplianceCategories}
                    rowKey={record => record.id}
                    style={{
                        width: "100%",
                    }}
                />
            </Flex>
        </Flex>
    );
};

export default KaspiComplianceCategoryPage;